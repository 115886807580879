import { styled } from "@mui/material";
import { useIntl } from "react-intl";
import { MINUTE_SECONDS, formatTimeDurationDay } from "tp/shared/common/format";
import React, { ReactElement } from "react";
import { DayTaskSummary as Messages } from "../../messages";
import { AddShiftData } from "../dialogs/AddShiftPopover/AddShiftPopover";

type Task = Pick<AddShiftData["dayTaskTemplates"][0]["taskTemplates"][0], "break" | "fromSec" | "untilSec" | "costDivision">

export type DayTaskSummaryProps = {
    tasks: Task[],
};

const DenseParagraph = styled("p")({ marginBlock: 0 });

export function DayTaskSummary(props: DayTaskSummaryProps): ReactElement {
    const { tasks } = props;

    const moreThanOneCostDivision = tasks
        .some(t => t.costDivision.ref !== tasks[0].costDivision.ref);

    return (
        <>
            {tasks
                .sort((a, b) => a.fromSec - b.fromSec)
                .map((t, i) =>
                    <DenseParagraph key={i}>
                        <TaskSummary task={t} showCostDivision={moreThanOneCostDivision}/>
                    </DenseParagraph>)}
        </>);
}

type TaskSummaryProps = {
    task: Task,
    showCostDivision: boolean,
};

const SuggestedLineBreakSection = styled("span")({ display: "inline-block" });

function TaskSummary(props: TaskSummaryProps): ReactElement {
    const { task, showCostDivision } = props;

    const intl = useIntl();
    const hasCustomBreak = !!task.break;
    const hasBreakInterval = typeof task.break?.fromSec === "number";
    const hasNoBreak = task.break?.lengthSec === 0;

    let taskInterval = `${formatTimeDurationDay(task.fromSec)}-${formatTimeDurationDay(task.untilSec)}`;
    if(showCostDivision) taskInterval += ` (${task.costDivision.abbreviation})`;
    if(hasCustomBreak) taskInterval += ",";

    let taskBreak;
    if(hasCustomBreak){
        if(hasNoBreak){
            taskBreak = intl.formatMessage(Messages.NoBreak);
        }else if(hasBreakInterval){
            taskBreak = `${intl.formatMessage(Messages.Break)} ${formatTimeDurationDay(task.break.fromSec)}-${formatTimeDurationDay(task.break.fromSec + task.break.lengthSec)}`;
        }else{
            taskBreak = intl.formatMessage(Messages.BreakInMinutes, { minutes: Math.round(task.break.lengthSec / MINUTE_SECONDS) });
        }
    }

    return (
        <>
            <SuggestedLineBreakSection>{taskInterval}</SuggestedLineBreakSection>
            {hasCustomBreak && <SuggestedLineBreakSection>{taskBreak}</SuggestedLineBreakSection>}
        </>);
}