// level 1 - raw colors
export const TPBlue = "#00314a";
export const TPYellow = "#fdb913";
export const TPTurquoise = "#86b7bc";
export const TPPink = "#F4486D";
export const TPMobilePink = "#E31F74";
export const TPTurquoiseGray = "#C9DDDF";
export const TpMobileTurquoise = "#77A0A4";
export const TPRed = "#D71F4B";
export const TPGreen = "#238321";
export const TPSeaGreen = "#008B8B";
export const TPDirtyGreen = "#5b7c80";
export const TPQuarterly = "#335a6e";
export const TPOrange = "#FD6D13";
export const White = "#FFF";
export const Black = "#000";

// Tints
export const TPBlue_Light = "#668392";
export const TPTurquoise_Light = "#A4BCC1";
export const TPRed_Light = "#f3bcc9";
export const TPGreen_Light = "#d7f4da";

//Shades
export const TPBlue_Dark = "#002234";
export const TPTurquoise_Dark = "#4C6B6E";
export const TPRed_Dark = "#971635";

// Grays
export const Gray0 = "#FAFAFA";
export const Gray1 = "#E5E5E5";
export const Gray2 = "#CECECE";
export const Gray3 = "#9F9F9F";
export const Gray4 = "#808080";
export const Gray5 = "#737373";
export const Gray6 = "#5A5A5A";
export const Gray7 = "#414141";

function hexToRgb(hex: string): { r: number; g: number; b: number } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) } : null;
}

export function hexToRgba(hex: string, a: number): string {
    const { r, g, b } = hexToRgb(hex);

    return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function lighterShade(hex: string, factor: number): string {
    const { r, g, b } = hexToRgb(hex);

    return `rgb(${r + factor}, ${g + factor}, ${b + factor})`;
}
