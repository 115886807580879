import React, { ReactElement, useEffect, useState } from "react";
import { FormControl, FormHelperText, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { TaskTimeInput, TaskTimeInputProps } from "../../inputs/TaskTimeInput";
import { SelectInput } from "../../../../../views/common-forms/components/Selects";
import { BreakInput } from "../../inputs/BreakInput";
import { MaxLengthTextField } from "../../inputs/MaxLengthTextField";
import { AddShiftData } from "./AddShiftPopover";
import { ConditionalTooltip } from "../../../../../views/common/components/tooltips/ConditionalTooltip";
import { FormattedMessage, useIntl } from "react-intl";
import { AddShift as AddShiftMessages, TaskForm as Messages } from "../../../messages";
import { ReportProblemOutlined as ReportProblemOutlinedIcon } from "tp/shared/common-design/icons";
import { FormLabel, labelHeight } from "../../layout/FormLabel";
import { components } from "../../../../../shared/timeplan-api-schema";

export type Task = components["schemas"]["CreateDayTask.Task"]
    & { hotelRef?: number };

export type TaskFormProps =
    Partial<Pick<AddShiftData, "hotels" | "snapSec" | "breakIntervals" | "collectiveAgreements" | "taskTypes" | "useFoodBenefit">>
    & Pick<TaskTimeInputProps, "otherTaskTimes">
    & {
        showAdvancedSettings: boolean,
        useBreakRules: boolean,
        onTaskUpdate: (t: Task | null) => void,
    };

type FoodBenefitOption = "default" | "true" | "false" | "";

const marginTop = {
    withLabel: "12px",
    withoutLabel: `${12 + labelHeight}px`,
};
const collectiveAgreementAccordingToContractRef = -1;
const gridColumWidth = (500 - 40) / 18;

export function TaskForm(props: TaskFormProps): ReactElement {
    const { showAdvancedSettings, useBreakRules, onTaskUpdate, hotels, snapSec, breakIntervals, otherTaskTimes, collectiveAgreements, taskTypes, useFoodBenefit } = props;

    const intl = useIntl();
    const collectiveAgreementOptions =
        [{ 
            name: intl.formatMessage(Messages.CollectiveAgreementAccordingToContract),
            ref: collectiveAgreementAccordingToContractRef
        } as TaskFormProps["collectiveAgreements"][0]].concat(collectiveAgreements);
    const foodBenefitOptions = [
        { name: intl.formatMessage(Messages.FoodBenefitAccordingToContract), value: "default" },
        { name: intl.formatMessage(Messages.NoFoodBenefit), value: "false" },
        { name: intl.formatMessage(Messages.AddFoodBenefit), value: "true" },
    ];

    const defaultHotel = hotels.find(h => h.isDefault) || hotels[0];
    const defaultCostDivision = defaultHotel.costDivisions.find(h => h.isDefault) || defaultHotel.costDivisions[0];

    const [fromSec, setFromSec] = useState(NaN);
    const [untilSec, setUntilSec] = useState(NaN);
    const [comment, setComment] = useState("");
    const [breakFromSec, setBreakFromSec] = useState(NaN);
    const [breakLengthSec, setBreakLengthSec] = useState(NaN);
    const [selectedHotelRef, setSelectedHotelRef] = useState<number>(defaultHotel.ref);
    const [selectedCostDivisionRef, setSelectedCostDivisionRef] = useState<number>(defaultCostDivision.ref);
    const [selectedTaskTypeRef, setSelectedTaskTypeRef] = useState(taskTypes.find(tt => tt.kind === "NormTime").ref);
    const [selectedCollectiveAgreementRef, setSelectedCollectiveAgreementRef] = useState<number>(collectiveAgreementAccordingToContractRef);
    const [selectedFoodBenefitOption, setSelectedFoodBenefitOption] = useState<FoodBenefitOption>("default");

    const customCollectiveAgreementSelected = selectedCollectiveAgreementRef != collectiveAgreementAccordingToContractRef;

    useEffect(() => {
        if (!isNaN(fromSec) && !isNaN(untilSec)
            && (useBreakRules || breakLengthSec === 0 || (!isNaN(breakFromSec) && breakLengthSec > 0))) {
            onTaskUpdate({
                fromSec,
                untilSec,
                comment,
                break: useBreakRules
                    ? null
                    : { fromSec: breakFromSec || 0, lengthSec: breakLengthSec },
                hotelRef: selectedHotelRef,
                costDivisionRef: selectedCostDivisionRef,
                taskTypeRef: selectedTaskTypeRef,
                foodBenefit: selectedFoodBenefitOption != "default" ? selectedFoodBenefitOption === "true" : undefined,
                collectiveAgreementRef: selectedCollectiveAgreementRef != collectiveAgreementAccordingToContractRef ? selectedCollectiveAgreementRef : undefined,
            });
        } else {
            onTaskUpdate(null);
        }
    }, [fromSec, untilSec, comment, breakFromSec, breakLengthSec, selectedHotelRef, selectedCostDivisionRef, useBreakRules, selectedCollectiveAgreementRef, selectedFoodBenefitOption, selectedTaskTypeRef]);

    const selectedHotel = hotels?.find(h => h.ref === selectedHotelRef);

    const multipleHotels = (!hotels || hotels.length > 1);
    const multipleHotelsOrUseFoodBenefit = (useFoodBenefit || multipleHotels);
    const gridColumnCount = showAdvancedSettings ? (multipleHotelsOrUseFoodBenefit ? 38 : 28) : 18;
    return (
        <>
            <Grid container spacing={0} rowSpacing={0} columnSpacing={0} width={gridColumnCount * gridColumWidth} columns={gridColumnCount}>
                <Grid item xs={9}>
                    <FormControl>
                        <FormLabel><FormattedMessage {...Messages.TaskTime} /></FormLabel>
                        <Stack direction="row" mt={marginTop.withLabel}>
                            <TaskTimeInput snapSec={snapSec ?? 0} fromSec={fromSec} untilSec={untilSec} onFromSecChange={setFromSec} onUntilSecChange={setUntilSec} otherTaskTimes={otherTaskTimes} />
                        </Stack>
                    </FormControl>
                </Grid>
                <Grid item xs={9}>
                    <SelectInput
                        sx={{ mt: marginTop.withoutLabel }}
                        value={selectedCostDivisionRef}
                        onChange={e => setSelectedCostDivisionRef(e.target.value as number)}
                        label={<FormattedMessage {...Messages.CostDivision} />}>
                        {selectedHotel && selectedHotel.costDivisions.map(cd => <MenuItem key={cd.ref} value={cd.ref}>{cd.name}</MenuItem>)}
                    </SelectInput>
                </Grid>
                {showAdvancedSettings && <Grid item xs={1} />}
                {showAdvancedSettings && <Grid item xs={9}>
                    <FormControl fullWidth>
                        <FormLabel><FormattedMessage {...Messages.AdvancedSettings} /></FormLabel>
                        <SelectInput
                            sx={{ mt: marginTop.withLabel }}
                            value={selectedCollectiveAgreementRef}
                            onChange={e => setSelectedCollectiveAgreementRef(e.target.value as number)}
                            label={<FormattedMessage {...Messages.CollectiveAgreement} />}>
                            {collectiveAgreements && collectiveAgreementOptions.map(cd => <MenuItem key={cd.ref} value={cd.ref}>{cd.name}</MenuItem>)}
                        </SelectInput>
                        {customCollectiveAgreementSelected && <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} component="div">
                            <Typography color="warning.main" position="absolute" right={0} fontSize={11} overflow="visible" noWrap>
                                <ReportProblemOutlinedIcon style={{ height: 15, width: 15, position: "relative", bottom: -3 }} color="warning" />
                                <FormattedMessage {...Messages.CollectiveAgreementWarning} />
                            </Typography>
                        </FormHelperText>}
                    </FormControl>
                </Grid>}
                {showAdvancedSettings && multipleHotelsOrUseFoodBenefit && <Grid item xs={1} />}
                {showAdvancedSettings && multipleHotelsOrUseFoodBenefit && <Grid item xs={9}>
                    {multipleHotels && <SelectInput
                        sx={{ mt: marginTop.withoutLabel }}
                        value={selectedHotelRef}
                        onChange={e => {
                            const ref = e.target.value as number;
                            setSelectedHotelRef(ref);
                            const hotel = hotels?.find(h => h.ref === ref);
                            setSelectedCostDivisionRef(hotel.costDivisions[0].ref);
                        }}
                        label={<FormattedMessage {...Messages.Company} />}>
                        {hotels && hotels.map(cd => <MenuItem key={cd.ref} value={cd.ref}>{cd.name}</MenuItem>)}
                    </SelectInput>}
                </Grid>}
                <Grid item xs={9}>
                    <ConditionalTooltip
                        conditional={useBreakRules}
                        useDiv
                        tooltipProps={{
                            title: <FormattedMessage
                                {...Messages.TurnOffBreakRules}
                                values={{ useBreakRulesSetting: <FormattedMessage {...AddShiftMessages.UseBreakRules} /> }} />,
                            followCursor: true
                        }}>
                        <BreakInput
                            disabled={useBreakRules}
                            snapSec={snapSec ?? 0}
                            taskFromSec={fromSec}
                            taskUntilSec={untilSec}
                            breakFromSec={breakFromSec}
                            breakLengthOptions={breakIntervals?.map(bi => bi.seconds) ?? []}
                            breakLengthSec={breakLengthSec}
                            onBreakFromSecChange={setBreakFromSec}
                            onBreakLengthSecChange={setBreakLengthSec} />
                    </ConditionalTooltip>
                </Grid>
                <Grid item xs={9}>
                    <MaxLengthTextField
                        sx={{ mt: marginTop.withoutLabel }}
                        maxLength={255}
                        fullWidth
                        size="small"
                        variant="outlined"
                        label={<FormattedMessage {...Messages.Comment} />}
                        multiline
                        rows={2.4}
                        value={comment}
                        onChange={e => setComment(e.target.value)} />
                </Grid>
                {showAdvancedSettings && <Grid item xs={1} />}
                {showAdvancedSettings && <Grid item xs={9}>
                    <SelectInput
                        sx={{ mt: marginTop.withoutLabel }}
                        value={selectedTaskTypeRef}
                        onChange={e => setSelectedTaskTypeRef(e.target.value as Task["taskTypeRef"])}
                        label={<FormattedMessage {...Messages.TaskType} />}>
                        {taskTypes && taskTypes.map(tt => <MenuItem key={tt.ref} value={tt.ref}>{tt.name}</MenuItem>)}
                    </SelectInput>
                </Grid>}
                {showAdvancedSettings && multipleHotelsOrUseFoodBenefit && <Grid item xs={1} />}
                {showAdvancedSettings && multipleHotelsOrUseFoodBenefit && <Grid item xs={9}>
                    {useFoodBenefit && <SelectInput
                        sx={{ mt: marginTop.withoutLabel }}
                        value={selectedFoodBenefitOption}
                        onChange={e => setSelectedFoodBenefitOption(e.target.value as FoodBenefitOption)}
                        label={<FormattedMessage {...Messages.FoodBenefit} />}>
                        {foodBenefitOptions.map(fbo => <MenuItem key={fbo.name} value={fbo.value}>{fbo.name}</MenuItem>)}
                    </SelectInput>}
                </Grid>}
            </Grid >
        </>);
}
