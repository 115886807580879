import { Alert, Autocomplete, Box, Button, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Close, Shortcut } from "tp/shared/common-design/icons";
import { formatTimeDuration } from "tp/shared/common/format";
import { TaskForm, TaskRotation } from "tp/site-admin/common/messages";
import { Colors } from "tp/shared/common-design";
import { SelectInput } from "tp/views/common-forms/components/Selects";
import { TimeTextField } from "tp/views/common/components/inputs/TimeTextField";
import { Common } from "tp/views/common/messages";
import { useAsyncEffect } from "tp/shared/common/react-helper";
import { initialize, taskrotationGettaskrotationlistGet } from "tp/shared/timeplan-api-client";
import { getUrls } from "tp/shared/common/reducers/runtime";
import { ActionPopover } from "tp/site-admin/common/components/dialogs/ActionPopover";
import { components } from "tp/shared/timeplan-api-schema";

export type TaskRotationData = components["schemas"]["TaskRotationList"];

export interface TaskRotationModalProps {
    key: any;
    taskRotationRef: number;
    selectedDayTaskTemplateRef: number;
    selectedDeviationRef: number;
    selectedPartTimeDeviationRef: number;
    ptDeviationTimeInSec: number;
    useDivisions: boolean;
    selectedCostDivisionOrDivisionRef: number;
    open: boolean;
    hasChanged: boolean;
    onSave: (taskRotationRef: number, dayTaskTemplateRef: number, deviationCodeRef: number, partTimeDeviationCodeRef: number, deviationTimeStr: string) => void;
    onClose: () => void;
    onQuickLinkClick: (dayTaskRef: number) => void;
}
export interface TaskRotationModalEventProps {
    getTaskRotationList: (hotelRef: number, dayTaskTemplateRef: number) => Promise<TaskRotationData>;
}

export function TaskRotationModal(props: TaskRotationModalProps): React.ReactElement {
    const getTaskRotationList = async (hotelRef: number, dayTaskTemplateRef: number) => {
        initialize(getUrls());
        return await taskrotationGettaskrotationlistGet(props.taskRotationRef, props.useDivisions, dayTaskTemplateRef, props.selectedCostDivisionOrDivisionRef, hotelRef);
    };
    return <_TaskRotationModal {...props} getTaskRotationList={getTaskRotationList} ></_TaskRotationModal>;
}

export function _TaskRotationModal(props: TaskRotationModalProps & TaskRotationModalEventProps): React.ReactElement {
    const {
        taskRotationRef,
        selectedDayTaskTemplateRef,
        selectedDeviationRef,
        selectedPartTimeDeviationRef,
        ptDeviationTimeInSec: ptDeviationTimeString,
        open,
        hasChanged,
        onSave,
        onClose,
        onQuickLinkClick,
        getTaskRotationList
    } = props;
    const [dayTaskList, setDayTaskList] = useState<TaskRotationData["dayTaskList"]>([]);
    const [deviationList, setDeviationList] = useState<TaskRotationData["deviationCodeList"]>([]);
    const [partTimeDeviationList, setPartTimeDeviationList] = useState<TaskRotationData["partTimeDeviationCodeList"]>([]);
    const [hotelList, setHotelList] = useState<TaskRotationData["hotelList"]>([]);
    const [hotel, setHotel] = useState(0);
    const [dayTask, setDayTask] = useState<TaskRotationData["dayTaskList"][0]>(null);
    const [deviation, setDeviation] = useState(selectedDeviationRef);
    const [partTimeDeviation, setPartTimeDeviation] = useState(selectedPartTimeDeviationRef);
    const [deviationTimeString, setDeviationTimeString] = useState(isNaN(ptDeviationTimeString) ? "" : formatTimeDuration(ptDeviationTimeString));
    const [disabledTimeInput, setDisabledTimeInput] = useState(false);
    const [hasAccess, setHasAccess] = useState(true);
    const intl = useIntl();

    useAsyncEffect(async () => {
        initialize(getUrls());
        const result = await getTaskRotationList(0, selectedDayTaskTemplateRef);
        if (result) {
            updateState(result, selectedDayTaskTemplateRef);
            setHasAccess(true);
        } else {
            setHasAccess(false);
        }
    }, []);

    useAsyncEffect(async () => {
        const index = partTimeDeviationList.findIndex(ptd => ptd.deviationCodeRef === partTimeDeviation);
        if (index != -1 && partTimeDeviation != 0 && partTimeDeviationList[index].scope) {
            setDeviationTimeString(partTimeDeviationList[index].scope.replace(".", ",") + "%");
            setDisabledTimeInput(true);
        } else {
            if (deviationTimeString.indexOf("%") != -1 || partTimeDeviation == 0) {
                setDeviationTimeString("");
            }
            setDisabledTimeInput(false);
        }
    }, [partTimeDeviation, partTimeDeviationList]);

    const updateState = async (result: TaskRotationData, dayTaskTemplateRef: number) => {
        setDayTask(result.dayTaskList.find((t) => t.dayTaskTemplateRef === dayTaskTemplateRef));
        setDayTaskList(result.dayTaskList);
        setDeviationList(result.deviationCodeList);
        setPartTimeDeviationList(result.partTimeDeviationCodeList);
        setHotelList(result.hotelList);
        setHotel(result.hotelRef);
    };

    const updateHotel = async (hotelRef: number) => {
        setHotel(hotelRef);
        updateState(await getTaskRotationList(hotelRef, 0), selectedDayTaskTemplateRef);
    };

    const handleSave = () => {
        onSave(taskRotationRef, dayTask?.dayTaskTemplateRef ?? 0, deviation, partTimeDeviation, deviationTimeString);
        onClose();
    };

    const handleOnQuickLinkClicked = () => {
        if (!hasChanged || confirm(intl.formatMessage((Common.confirmNavigateOnEdit)))) {
            onQuickLinkClick(dayTask?.dayTaskTemplateRef ?? 0);
        }
    };

    const mainBoxStyle = {
        width: "500px"
    };
    const mainTitleStyle = {
        color: Colors.TPBlue,
        height: 15,
        margin: 1
    };
    const titleStyle = {
        height: 15,
        margin: 1
    };
    const boxPadding = {
        paddingRight: "1rem",
        paddingLeft: "1rem",
    };
    const boxStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    };
    const selectStyle = {
        margin: 1,
        width: "60%"
    };
    const actions = ([
        <Button key={"save"} variant="contained" onClick={handleSave}>
            <FormattedMessage {...Common.save} />
        </Button>,
        <Button key={"cancel"} variant="contained" onClick={onClose}>
            <FormattedMessage {...Common.close} />
        </Button>
    ]);

    return (
        <ActionPopover
            open={open}
            onClose={onClose}
            keepMounted={false}
            anchorOrigin={{ vertical: "center", horizontal: "center" }}
            transformOrigin={{ vertical: "center", horizontal: "center" }}
            titleElement={<div></div>}
            actions={hasAccess ? actions : null}>
            {hasAccess ?
                <Box sx={mainBoxStyle}>
                    <Box sx={boxPadding}>
                        <Typography sx={mainTitleStyle} variant="h2" component="h2">
                            <FormattedMessage {...TaskRotation.Work} />
                        </Typography>
                        <Box sx={boxStyle}>
                            <Typography sx={titleStyle} variant="h3" component="h3">
                                <FormattedMessage {...TaskForm.Company} />
                            </Typography>
                            <SelectInput
                                sx={selectStyle}
                                value={hotel === 0 ? "" : hotel}
                                onChange={async (e) => updateHotel(e.target.value as number)}
                                label={null}>
                                {hotelList && hotelList.map(h => <MenuItem sx={{ fontSize: 14 }} key={h.hotelRef} value={h.hotelRef}>{h.hotelName}</MenuItem>)}
                            </SelectInput>
                        </Box>
                    </Box>
                    <Box sx={[boxStyle, boxPadding]}>
                        <Typography sx={titleStyle} variant="h3" component="h3">
                            <FormattedMessage {...TaskRotation.Task} />
                        </Typography>
                        <Box sx={[boxStyle, selectStyle]}>
                            <Autocomplete
                                value={dayTask}
                                options={dayTaskList}
                                sx={{ width: "100%", }}
                                noOptionsText={intl.formatMessage((Common.noResultsFound))}
                                getOptionLabel={(option) => option.displayName.toString()}
                                disableClearable
                                renderOption={(props, option, state) => {
                                    return (
                                        <Box
                                            key={option.dayTaskTemplateRef}
                                            value={option.dayTaskTemplateRef}
                                            component="li"
                                            sx={{ background: option.colorCode, color: (state.selected ? Colors.Black : option.textColor), fontSize: 14, marginLeft: 0, "&:hover": { color: Colors.Black, } }}
                                            {...props}
                                        >
                                            {option.displayName}
                                        </Box>
                                    );
                                }}
                                renderInput={(params) => <TextField {...params} size="small" />}
                                onChange={(event, value) => { setDayTask(value); }}
                            />
                            <IconButton onClick={() => setDayTask(null)} disabled={!dayTask}><Close></Close></IconButton>
                            <IconButton onClick={handleOnQuickLinkClicked} disabled={!dayTask}><Shortcut></Shortcut></IconButton>
                        </Box>
                    </Box>
                    <Box sx={boxPadding}>
                        <Typography sx={mainTitleStyle} variant="h2" component="h2">
                            <FormattedMessage {...TaskRotation.Deviation} />
                        </Typography>
                        <Box sx={boxStyle}>
                            <Typography sx={titleStyle} variant="h3" component="h3">
                                <FormattedMessage {...TaskRotation.DeviationCode} />
                            </Typography>
                            <Box sx={[boxStyle, selectStyle]}>
                                <SelectInput
                                    sx={{ width: "100%" }}
                                    value={deviation === 0 ? "" : deviation}
                                    onChange={e => setDeviation(e.target.value as number)}
                                    label={null}>
                                    {deviationList && deviationList.map(d => <MenuItem sx={{ fontSize: 14 }} key={d.deviationCodeRef} value={d.deviationCodeRef}>{d.deviationCodeName}</MenuItem>)}
                                </SelectInput>
                                <IconButton onClick={() => setDeviation(0)} disabled={deviation === 0}><Close></Close></IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={boxPadding}>
                        <Typography sx={mainTitleStyle} variant="h2" component="h2">
                            <FormattedMessage {...TaskRotation.PartTimeDeviation} />
                        </Typography>
                        <Box sx={boxStyle}>
                            <Typography sx={titleStyle} variant="h3" component="h3">
                                <FormattedMessage {...TaskRotation.DeviationCode} />
                            </Typography>
                            <Box sx={[boxStyle, selectStyle]}>
                                <SelectInput
                                    sx={{ width: "100%" }}
                                    value={partTimeDeviation === 0 ? "" : partTimeDeviation}
                                    onChange={e => setPartTimeDeviation(e.target.value as number)}
                                    label={null}>
                                    {partTimeDeviationList && partTimeDeviationList.map(ptd => <MenuItem sx={{ fontSize: 14 }} key={ptd.deviationCodeRef} value={ptd.deviationCodeRef}>{ptd.deviationCodeName}</MenuItem>)}
                                </SelectInput>
                                <IconButton onClick={() => setPartTimeDeviation(0)} disabled={partTimeDeviation === 0}><Close></Close></IconButton>
                            </Box>
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={titleStyle} variant="h3" component="h3">
                                <FormattedMessage {...TaskRotation.Time} />
                            </Typography>
                            <Box sx={[boxStyle, { margin: 1 }]}>
                                <TimeTextField
                                    onChange={e => setDeviationTimeString(e.target.value)}
                                    value={deviationTimeString}
                                    error={!!(deviationTimeString && isNaN(ptDeviationTimeString))}
                                    disabled={disabledTimeInput}
                                />
                                <IconButton disabled={disabledTimeInput || !deviationTimeString} onClick={() => setDeviationTimeString("")}><Close></Close></IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box> :
                <Box sx={mainBoxStyle}>
                    <Alert severity="error">
                        <FormattedMessage {...TaskRotation.NoAccess} />
                    </Alert>
                </Box>}
        </ActionPopover>);
}
