import { AlertColor, alpha } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React, { ReactNode } from "react";
import { CheckCircleRounded, ErrorRounded, InfoRounded, WarningAmberRounded } from "tp/shared/common-design/icons";

import { Gray0, Gray1, Gray4, Gray5, TPBlue, TPGreen, TPPink, TPRed, TPTurquoise, TPYellow } from "../../shared/common-design/colors";
import { TpAdminFontFamily } from "../common-design/fonts";

export const iconMapping: Record<AlertColor, ReactNode> = {
    success: <CheckCircleRounded />,
    info: <InfoRounded />,
    error: <ErrorRounded />,
    warning: <WarningAmberRounded />
};

export const theme = createTheme({
    palette: {
        primary: {
            main: TPBlue,
            light: TPTurquoise,
        },
        secondary: {
            main: TPPink,
        },
        error: {
            main: TPRed,
        },
        info: {
            main: TPTurquoise
        },
        success: {
            main: TPGreen,
        },
        warning: {
            main: TPYellow,
        },
        action: {
            disabled: Gray1,
            disabledBackground: Gray0
        },
        background: {
            default: Gray0,
            paper: Gray0,
        }
    },
    typography: {
        fontFamily: TpAdminFontFamily,
        allVariants: {
            textTransform: "none",
        },
        h1: {
            fontSize: 32,
            fontWeight: "bold",
            textAlign: "center",
        },
        h2: {
            fontSize: 16,
            lineHeight: 1.6,
        },
        h3: {
            fontSize: 14,
        },
        h4: {
            fontSize: 12,
            fontStyle: "italic",
        },
        subtitle1: {
            fontWeight: "bold",
            fontSize: 14,
            color: Gray5
        }
    },
    components: {
        MuiAlert: {
            styleOverrides: {
                icon: {
                    marginRight: 16,
                    fontSize: 24,
                    padding: "5px 0",
                    alignSelf: "center"
                }
            },
            defaultProps: {
                iconMapping
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        backgroundColor: alpha(TPBlue, 0.2),
                    },
                    "&:hover": {
                        backgroundColor: TPTurquoise,
                    },
                    "&.MuiButton-sizeSmall": {
                        fontSize: "12px"
                    }
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: ({ theme }) => ({
                    "&.Mui-disabled": {
                        color: theme.palette.text.disabled
                    }
                })
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    fontSize: 12
                },
                root: ({ theme }) => ({
                    background: theme.palette.common.white,
                    ["& legend"]: {
                        maxWidth: 0
                    }
                })
            }
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    "&:after": {
                        borderBottomColor: TPYellow,
                    }
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: "12px",
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: "14px",
                },
                outlined: {
                    fontSize: "12px",
                    ["&.MuiInputLabel-shrink"]: {
                        transform: "translate(14px, -13.5px) scale(0.75)"
                    }
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                dense: {
                    height: 40,
                    minHeight: 40
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 10
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "16px"
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    "*::-webkit-scrollbar": {
                        width: "12px",
                    },
                    "*::-webkit-scrollbar-track": {
                        background: Gray1,
                        borderRadius: "8px"
                    },
                    "*::-webkit-scrollbar-thumb": {
                        background: Gray4,
                        borderRadius: "5px",
                        width: "8px"
                    },
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    overflow: "hidden"
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    boxShadow: "0 0 5px black"
                },
            },
        },
    }
});
