import { Icon, IconButton, ListItem, ListItemButton, ListItemText, PopoverProps, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React, { ReactElement, useRef } from "react";
import { DayTaskTemplateAccordion as DayTaskTemplateMessages } from "../../../messages";
import { Delete as DeleteIcon } from "tp/shared/common-design/icons";
import { DayTaskSummary } from "../DayTaskSummary";
import { DayTaskTemplateListProps } from "./DayTaskTemplateList";
import { DayTaskTemplateTitle } from "./DayTaskTemplateTitle";
import ErrorDialog from "../../dialogs/ErrorDialog";
import { DeleteResponse } from "../../dialogs/AddShiftPopover/AddShiftPopover";
import { ConfirmDialog } from "../../dialogs/ConfirmDialog";

type DayTaskTemplateItemProps = {
    dayTaskTemplate: DayTaskTemplateListProps["dayTaskTemplates"][0],
    onAdd: DayTaskTemplateListProps["onAdd"],
    onDelete?: DayTaskTemplateListProps["onDelete"],
    isDisabled?: DayTaskTemplateListProps["isDisabled"],
};

const AddIcon = () => <Icon sx={{ p: 1.5, fontSize: "1.5rem !important" }} className="fas fa-plus-circle" color="primary" />;

export function DayTaskTemplateItem(props: DayTaskTemplateItemProps): ReactElement {
    const { dayTaskTemplate, onAdd, onDelete, isDisabled } = props;

    const [deleteCandidate, setDeleteCandidate] = React.useState(undefined);
    const [deleteError, setDeleteError] = React.useState(undefined);
    const anchorRef = useRef(null);

    const handleAdd = () => onAdd(dayTaskTemplate);

    const handleDelete = () => {
        setDeleteCandidate(dayTaskTemplate.ref);
        setDeleteError(undefined);
    };

    const handleDeleteConfirmation = () => {
        setDeleteCandidate(undefined);
        onDelete(deleteCandidate)
            .then((data: DeleteResponse) => {
                if(data !== undefined && data.status !== 200){
                    setDeleteError(data);
                }
            }).catch((error) => {
                setDeleteError(error);
            });
    };

    const clearDialogs = () => {
        setDeleteCandidate(undefined);
        setDeleteError(undefined);
    };

    const dialogAnchor: Pick<PopoverProps, "anchorEl" | "anchorOrigin" | "transformOrigin"> = {
        anchorEl: anchorRef.current,
        anchorOrigin: { vertical: "center", horizontal: "center" },
        transformOrigin: { vertical: "center", horizontal: "center" },
    };

    const disabled = isDisabled && isDisabled(dayTaskTemplate);

    return (
        <ListItem
            ref={anchorRef}
            divider
            sx={{ p: 0 }}
            secondaryAction={onDelete && <IconButton sx={{ p: 1.5 }} onClick={handleDelete} disabled={disabled}><DeleteIcon /></IconButton>}
        >
            <ListItemButton sx={{ px: 0 }} dense onClick={handleAdd} disabled={disabled}>
                <AddIcon />
                <ListItemText
                    primary={<DayTaskTemplateTitle name={dayTaskTemplate.name} abbreviation={dayTaskTemplate.abbreviation} />}
                    secondary={<DayTaskSummary tasks={dayTaskTemplate.taskTemplates} />}
                    secondaryTypographyProps={{ variantMapping: { body2: "div" } }}
                />
            </ListItemButton>
            <ConfirmDialog
                title={<Typography><FormattedMessage {...DayTaskTemplateMessages.DeleteTemplate} /></Typography>}
                open={!!deleteCandidate}
                onConfirm={handleDeleteConfirmation}
                onClose={clearDialogs}
                {...dialogAnchor} />

            <ErrorDialog 
                error={deleteError}
                onOkClick={clearDialogs}
            />
        </ListItem>);
}