import { defineMessages } from "react-intl";

export const AddShift = defineMessages({
    SaveAsTemplate: {
        id: "AddShift.SaveAsTemplate",
        defaultMessage: "Save as template",
    },
    TemplateName: {
        id: "AddShift.TemplateName",
        defaultMessage: "Name of the shift",
    },
    Title: {
        id: "AddShift.Title",
        defaultMessage: "Add shift {date}",
    },
    UseBreakRules: {
        id: "AddShift.UseBreakRules",
        defaultMessage: "Break according to the contract",
    },
    UseBreakRulesSubtitle: {
        id: "AddShift.UseBreakRulesSubtitle",
        defaultMessage: "The break is automatically calculated according to the contract",
    },
    NewShift: {
        id: "AddShift.NewShift",
        defaultMessage: "New shift",
    },
    AddTask: {
        id: "AddShift.AddTask",
        defaultMessage: "Add time interval",
    },
    AddAndCreateTemplate: {
        id: "AddShift.AddAndCreateTemplate",
        defaultMessage: "Add and Save",
    },
    SavedShifts: {
        id: "AddShift.SavedShifts",
        defaultMessage: "Saved shifts",
    },
    AdvancedSettings: {
        id: "AddShift.AdvancedSettings",
        defaultMessage: "More settings",
    },
    HideAdvancedSettings: {
        id: "AddShift.HideAdvancedSettings",
        defaultMessage: "Fewer settings",
    },
} as const);

export const DayTaskSummary = defineMessages({
    BreakInMinutes: {
        id: "DayTaskSummary.BreakInMinutes",
        defaultMessage: "break {minutes} min",
    },
    Break: {
        id: "DayTaskSummary.Break",
        defaultMessage: "break",
    },
    NoBreak: {
        id: "DayTaskSummary.NoBreak",
        defaultMessage: "no break",
    },
} as const);

export const DayTaskTemplateAccordion = defineMessages({
    DeleteTemplate: {
        id: "DayTaskTemplateAccordion.DeleteTemplate",
        defaultMessage: "Delete the shift?",
    },
} as const);

export const TaskForm = defineMessages({
    TaskTime: {
        id: "TaskForm.TaskTime",
        defaultMessage: "Time",
    },
    CostDivision: {
        id: "TaskForm.CostDivision",
        defaultMessage: "Department",
    },
    TurnOffBreakRules: {
        id: "TaskForm.TurnOffBreakRules",
        defaultMessage: "Turn off \"{useBreakRulesSetting}\" to overrule the break rules",
    },
    Comment: {
        id: "TaskForm.Comment",
        defaultMessage: "Comment",
    },
    AdvancedSettings: {
        id: "TaskForm.AdvancedSettings",
        defaultMessage: "Advanced settings",
    },
    Company: {
        id: "TaskForm.Company",
        defaultMessage: "Company",
    },
    CollectiveAgreement: {
        id: "TaskForm.CollectiveAgreement",
        defaultMessage: "Collective agreement",
    },
    CollectiveAgreementAccordingToContract: {
        id: "TaskForm.CollectiveAgreementAccordingToContract",
        defaultMessage: "Coll.ag. according to contract",
    },
    CollectiveAgreementWarning: {
        id: "TaskForm.CollectiveAgreementWarning",
        defaultMessage: "Only change collective agreement in exceptional cases",
    },
    FoodBenefit: {
        id: "TaskForm.FoodBenefit",
        defaultMessage: "Food benefit",
    },
    FoodBenefitAccordingToContract: {
        id: "TaskForm.FoodBenefitAccordingToContract",
        defaultMessage: "Food benefit according to contract",
    },
    NoFoodBenefit: {
        id: "TaskForm.NoFoodBenefit",
        defaultMessage: "No food benefit",
    },
    AddFoodBenefit: {
        id: "TaskForm.AddFoodBenefit",
        defaultMessage: "Add food benefit",
    },
    TaskType: {
        id: "TaskForm.TaskType",
        defaultMessage: "Shift type",
    },
    Inactive: {
        id: "TaskForm.Inactive",
        defaultMessage: " (Inactive)"
    }
} as const);

export const BreakInput = defineMessages({
    Label: {
        id: "BreakInput.Label",
        defaultMessage: "Break",
    },
    NoBreak: {
        id: "BreakInput.NoBreak",
        defaultMessage: "No break",
    },
} as const);

export const TimeInput = defineMessages({
    Starttime: {
        id: "TimeInput.StartTime",
        defaultMessage: "Start",
    },
    Endtime: {
        id: "TimeInput.EndTime",
        defaultMessage: "End",
    },
    NextDay: {
        id: "TimeInput.NextDay",
        defaultMessage: "Next day",
    },
    Overlap: {
        id: "TimeInput.Overlap",
        defaultMessage: "Overlap",
    },
    InvalidTime: {
        id: "TimeInput.InvalidTime",
        defaultMessage: "Invalid time",
    },
    InvalidLength: {
        id: "TimeInput.InvalidLength",
        defaultMessage: "Invalid length",
    },
} as const);

export const HelpCenterMessages = defineMessages({
    Title: {
        id: "HelpCenter.Title",
        defaultMessage: "Getting started",
    },
    RecommendedGuides: {
        id: "HelpCenter.RecommendedGuides",
        defaultMessage: "Recommended guides",
    },
    StartupGuides: {
        id: "HelpCenter.StartUpGuides",
        defaultMessage: "Startup guides",
    },
    ShowAll: {
        id: "HelpCenter.ShowAll",
        defaultMessage: "Show all",
    },
    NoGuides: {
        id: "HelpCenter.NoGuides",
        defaultMessage: "There are no videos to show",
    },
    CloseDialog: {
        id: "HelpCenter.CloseDialog",
        defaultMessage: "Close",
    },
    Done: {
        id: "HelpCenter.Done",
        defaultMessage: "You’re done!",
    },
    AfterVideosAreWatchedText: {
        id: "HelpCenter.AfterVideosAreWatchedText",
        defaultMessage:
            "You’ll find more useful guides in the list of recommended guides.\nYou can also search our help center or chat with our support team by clicking the chat bubble in the lower right corner.",
    },
} as const);

export const CopyToClipboardButton = defineMessages({
    Copy: {
        id: "CopyToClipboardButton.Copy",
        defaultMessage: "Copy to clipboard",
    },
    Copied: {
        id: "CopyToClipboardButton.Copied",
        defaultMessage: "Copied to clipboard",
    },
} as const);

export const TaskRotation = defineMessages({
    Work: {
        id: "TaskRotation.Work",
        defaultMessage: "Work",
    },
    Task: {
        id: "TaskRotation.Task",
        defaultMessage: "Shift",
    },
    Deviation: {
        id: "TaskRotation.Deviation",
        defaultMessage: "Absence entire day",
    },
    PartTimeDeviation: {
        id: "TaskRotation.PartTimeDeviation",
        defaultMessage: "Absence part of day",
    },
    DeviationCode: {
        id: "TaskRotation.DeviationCode",
        defaultMessage: "Deviation code",
    },
    Time: {
        id: "TaskRotation.Time",
        defaultMessage: "Time",
    },
    NoAccess: {
        id: "TaskRotation.NoAccess",
        defaultMessage:
            "You are not allowed to change anything because you have no access to the department which the selected shift belongs to or the department is disabled.",
    },
} as const);
